import React from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Link, Element } from "react-scroll";

function App() {
  return (
    <div className="App">
      <Grid container>
        <Grid
          container
          size={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 8,
            height: "80px",
          }}
        >
          <Grid size={2}>
            <Link to="about" smooth={true} duration={1000}>
              <Typography variant="h4" fontFamily={"CZ-Regular"}>
                About
              </Typography>
            </Link>
          </Grid>
          <Grid
            size={8}
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Stack>
              <Typography variant="h2" fontFamily={"CZ-Regular"} color="gold">
                Les Gold
              </Typography>
              <Grid
                container
                mt={3}
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <a href="https://open.spotify.com/artist/3XfOlbu8GzAWZgunWPap5s">
                  <img
                    src="https://storage.googleapis.com/pr-newsroom-wp/1/2023/05/Spotify_Primary_Logo_RGB_Black.png"
                    alt="Spotify"
                    width={"25px"}
                  />
                </a>

                <a href="https://www.youtube.com/channel/UCCHQ9PnilyXKIIEAA89XhDw">
                  <img
                    src="/yt_logo_mono_light.png"
                    alt="Youtube"
                    width={"105px"}
                  />
                </a>
                <a href="https://instagram.com/lesgoldmusic">
                  <img
                    src="/Instagram_Glyph_Black.jpg"
                    alt="Instgram"
                    width={"25px"}
                  />
                </a>
              </Grid>
            </Stack>
          </Grid>
          <Grid size={2}>
            <Typography variant="h4" fontFamily={"CZ-Regular"}>
              Shows
            </Typography>
          </Grid>
        </Grid>

        <Grid size={12} mt={15}>
          <img src="/IMG_4912.jpg" alt="Les Gold" width={"70%"} />
        </Grid>

        <Element name="about">
          <Grid
            size={12}
            mt={10}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 8,
            }}
          >
            <Stack
              spacing={2}
              style={{
                width: "75%",
              }}
            >
              <Typography variant="h4" fontFamily={"CZ-Regular"}>
                About Les Gold
              </Typography>
              <Typography variant="body1">
                Portland indie rock outfit Les Gold was founded by frontman
                Jenson Tuomi and guitarist Tim Kaye, and consists of bassist
                Jake Jarvis, drummer Alexander Geiszler, and lead guitarist Pete
                Abraham. The band has cultivated a sound that mixes homegrown
                Northwest indie rock with elements of folk rock, dream pop,
                desert rock, surf, and other influences. Les Gold showcases
                catchy, poppy melodies and tones layered over unique vocal
                harmonies and often macabre lyrics – crafting a sound that’s
                been described as ‘gloom disco’ and ‘bummer fun’.
              </Typography>
            </Stack>
          </Grid>
        </Element>

        <Grid size={12} mt={10}>
          <img
            src="/fad81765-240d-4795-b0da-1f4cfd885381.jpg"
            alt="Les Gold"
            width={"70%"}
          />
        </Grid>

        <Grid
          size={12}
          mt={10}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 8,
          }}
        >
          <Stack spacing={2}>
            <Typography variant="h4" fontFamily={"CZ-Regular"}>
              Shows
            </Typography>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 1200 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ background: "#c1ddc7" }}>
                    <TableCell>Venue</TableCell>
                    <TableCell align="right">City</TableCell>
                    <TableCell align="right">Date</TableCell>
                    <TableCell align="right">Tickets</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key={0}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      background: "#f5e8c6",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Mississippi Studios
                    </TableCell>
                    <TableCell align="right">Portland, Oregon</TableCell>
                    <TableCell align="right">9-21-2024</TableCell>
                    <TableCell align="right">
                      <a href="https://www.etix.com/ticket/p/48204612/les-gold-portland-mississippi-studios?_gl=1*1se6vwv*_ga*MTcwNjU0NzM5Ny4xNzI1NTYzNTA3*_ga_T3044165VM*MTcyNTU2MzUwNi4xLjAuMTcyNTU2MzUwNi42MC4wLjA.&_ga=2.46185639.1281388398.1725563507-1706547397.1725563507">
                        Tickets
                      </a>{" "}
                    </TableCell>
                  </TableRow>
                  {/* <TableRow
                    key={0}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      background: "#f4d279",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Ron Toms
                    </TableCell>
                    <TableCell align="right">Portland, Oregon</TableCell>
                    <TableCell align="right">9-28-2024</TableCell>
                    <TableCell align="right">Tickets </TableCell>
                  </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
